import { Timestamp } from '@models/Timestamp';

import {
  SerializedTimestampJSON,
  SerializedTimestamp, SerializedTimestampFormats,
} from '../../types/Serialization.type';
import { isObject, TransformerFunction, transformRecursively } from './transformer.util';

function isTimestamp(inp: unknown): inp is Timestamp {
  return inp instanceof Timestamp;
}

function isSerializedTimestampJSON(obj: unknown): obj is SerializedTimestampJSON {
  return isObject(obj) && 'seconds' in obj && 'nanoseconds' in obj;
}

export const serializeTimestampToNumber: TransformerFunction<Timestamp, number> = (inp: Timestamp) => inp.toMillis();
export const serializeTimestampToDate: TransformerFunction<Timestamp, Date> = (inp: Timestamp) => inp.toDate();
export const serializeTimestampToJSON: TransformerFunction<Timestamp, SerializedTimestampJSON> = (inp: Timestamp) => ({
  seconds: inp.seconds, nanoseconds: inp.nanoseconds,
});

export const serializeJSONToTimestamp: TransformerFunction<SerializedTimestampJSON, Timestamp> = (inp: SerializedTimestampJSON) => new Timestamp(inp.seconds, inp.nanoseconds);

export function serializeTimestampFields<Input = unknown, SerializationFormat extends SerializedTimestampFormats = SerializedTimestampJSON>
(input: Input, serializer: TransformerFunction<Timestamp, SerializationFormat>): SerializedTimestamp<Input, SerializationFormat> {
  return transformRecursively(input, isTimestamp, serializer);
}

export function deserializeTimestampFields<T>(input: SerializedTimestamp<T, SerializedTimestampJSON>): T {
  return transformRecursively(input, isSerializedTimestampJSON, serializeJSONToTimestamp);
}
