import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import {
  FunctionDescriptor,
  FunctionMethod,
  FunctionName,
  FunctionNameWithoutParamsOrRequest,
  FunctionNameWithParams,
  FunctionNameWithParamsAndRequest,
  FunctionNameWithRequest,
  FunctionParams,
  FunctionPath,
  FunctionRequestType,
  FunctionResponseType,
  FunctionUrlParamsType,
} from '@assets/lambdas/Lambda.type';
import { from, map, Observable, throwError } from 'rxjs';
import { apiMapping } from '@assets/api-mapping';
import { BYPASS_ERROR_HANDLER } from '../interceptors/error-handler.interceptor';
import { GetBeneficiaryQrCodeStringRequest } from '@assets/requests/beneficiaries/GetBeneficiaryQrCodeString.request';
import { AddVoucherRestrictionRequest } from '@assets/requests/discreteVouchers/AddVoucherRestrictionRequest';
import { VoucherRestrictionModel } from '@assets/models/discreteVouchers/VoucherRestriction.model';
import {
  GetBeneficiaryVoucherRestrictionRequest,
} from '@assets/requests/discreteVouchers/GetBeneficiaryVoucherRestrictionRequest';
import { DeleteVoucherRestrictionRequest } from '@assets/requests/discreteVouchers/DeleteVoucherRestrictionRequest';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import { MonthAndYearRequest } from '@assets/requests/TimePeriod.request';
import {
  GetAgencyUnonboardedBeneficiariesRequest,
} from '@assets/requests/beneficiaries/GetAgencyUnonboardedBeneficiaries.request';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { AgencyInternalInformationModel } from '@assets/models/internalInformation/InternalInformation.model';
import {
  UpsertAgencyInternalInformationRequest,
} from '@assets/requests/internalInformation/UpsertInternalInformation.request';
import {
  ManageAgencyDeactivationScheduleRequest,
} from '@assets/requests/agencies/ManageAgencyDeactivationSchedule.request';
import { UpdateBusinessEligibilityRequest } from '@assets/requests/businesses/UpdateBusinessEligibility.request';
import {
  OnCallResponseData,
  SerializedTimestamp, SerializedTimestampJSON,
} from '@assets/types/Serialization.type';
import { deserializeTimestampFields } from '@assets/utils/functions/timestamp.util';

export interface FunctionCallOptions {
  bypassErrorHandler?: boolean;
}

type BackendClient = {
  'AUTH-checkIsActivatedUser_onCall': {
    request: { email: string },
    response: { isActivated: boolean }
  },
  'BENEFICIARIES-getBeneficiaryQrCodeString_onCall': {
    request: GetBeneficiaryQrCodeStringRequest,
    response: string
  },
  'DISCRETE_VOUCHERS-addVoucherRestriction_onCall': {
    request: AddVoucherRestrictionRequest,
    response: VoucherRestrictionModel
  },
  'DISCRETE_VOUCHERS-getBeneficiaryVoucherRestriction_onCall': {
    request: GetBeneficiaryVoucherRestrictionRequest,
    response: VoucherRestrictionModel[]
  },
  'DISCRETE_VOUCHERS-deleteVoucherRestriction_onCall': {
    request: DeleteVoucherRestrictionRequest,
    response: ConfirmationResponse
  },
  'INVOICES-getInvoicesZipStoragePath_onCall': {
    request: MonthAndYearRequest,
    response: string
  },
  'BENEFICIARIES-getAgencyUnonboardedBeneficiaries_onCall': {
    request: GetAgencyUnonboardedBeneficiariesRequest,
    response: BeneficiaryModel[]
  },
  'AGENCIES-getAgencyInternalInformation_onCall': {
    request: { agencyId: string },
    response: AgencyInternalInformationModel,
  },
  'AGENCIES-updateAgencyInternalInformation_onCall': {
    request: { agencyId: string } & UpsertAgencyInternalInformationRequest,
    response: ConfirmationResponse,
  },
  'AGENCIES-manageAgencyDeactivationSchedule_onCall': {
    request: { agencyId: string } & ManageAgencyDeactivationScheduleRequest,
    response: ConfirmationResponse,
  },
  'BUSINESSES-updateBusinessEligibility_onCall': {
    request: UpdateBusinessEligibilityRequest,
    response: void
  }
}

@Injectable({
  providedIn: 'root',
})
export class CallerService {
  #functions: Functions = inject(Functions);

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  callable<L extends FunctionNameWithoutParamsOrRequest>(
    functionName: L,
    options?: FunctionCallOptions,
  ): Observable<FunctionResponseType<L>>;
  callable<L extends FunctionNameWithParams>(
    functionName: L,
    params: FunctionUrlParamsType<L>,
    options?: FunctionCallOptions,
  ): Observable<FunctionResponseType<L>>;
  callable<L extends FunctionNameWithRequest>(
    functionName: L,
    request: FunctionRequestType<L>,
    options?: FunctionCallOptions,
  ): Observable<FunctionResponseType<L>>;
  callable<L extends FunctionNameWithParamsAndRequest>(
    functionName: L,
    params: FunctionUrlParamsType<L>,
    request: FunctionRequestType<L>,
    options?: FunctionCallOptions,
  ): Observable<FunctionResponseType<L>>;
  callable<L extends FunctionName>(
    functionName: L,
    params?: FunctionUrlParamsType<L>,
    request?: FunctionRequestType<L>,
    options?: FunctionCallOptions,
  ): Observable<FunctionResponseType<L>> {
    const { path: functionPath, verb: method, authType }: FunctionDescriptor<L> = apiMapping[functionName];
    const {
      functionParams,
      functionRequest,
      functionOptions,
    } = this.normalizeFunctionParams<L>(functionPath, params, request, options);

    if (!functionPath || !method) {
      return throwError(() => new Error('Cannot find corresponding descriptor'));
    }

    const url: string = this.generateUrl<L>(functionPath, functionParams);
    const context: HttpContext = this.generateContext(functionOptions);
    const headers: HttpHeaders = new HttpHeaders({ authType });

    return this.call$<FunctionRequestType<L>, FunctionResponseType<L>, L>(headers, method, url, context, functionRequest);
  }

  onCall$<FuncName extends keyof BackendClient, Req = BackendClient[FuncName]['request'], Res = BackendClient[FuncName]['response']>(
    functionName: FuncName,
    request: Req,
  ): Observable<Res> {
    return from(
      httpsCallable<Req, OnCallResponseData<Res>>(this.#functions, functionName)(request),
    ).pipe(
      map((result: HttpsCallableResult<OnCallResponseData<Res>>) => deserializeTimestampFields<Res>(result.data)),
    );
  }

  private normalizeFunctionParams<L extends FunctionName>(
    functionPath: FunctionPath<L>,
    params?: FunctionUrlParamsType<L> | FunctionRequestType<L> | FunctionCallOptions,
    request?: FunctionRequestType<L> | FunctionCallOptions,
    options?: FunctionCallOptions,
  ): ({
    functionParams: FunctionParams<L>,
    functionRequest: FunctionRequestType<L>,
    functionOptions?: FunctionCallOptions
  }) {
    const hasParams: boolean = /\/:([^\/]+)/g.test(functionPath);

    if (!hasParams && !!params) {
      options = request as FunctionCallOptions;
      request = params as FunctionRequestType<L>;
      params = void 0;
    }

    if (!!(request as FunctionCallOptions)?.bypassErrorHandler) {
      options = request as FunctionCallOptions;
      request = void 0;
    }

    return {
      functionParams: params as FunctionParams<L>,
      functionRequest: request as FunctionRequestType<L>,
      functionOptions: options as FunctionCallOptions,
    };
  }

  private generateUrl<L extends FunctionName>(path: FunctionPath<L>, params: FunctionParams<L>): string {
    const urlParamPattern = /\/:([^\/]+)/g;

    return path.replace(urlParamPattern, (_: string, paramKey: string) => {

      if (this.hasKeys(params)) {
        const paramExists: boolean = paramKey in params;

        if (paramExists) {
          return `/${(params)[paramKey]}`;
        }
      }

      throw new Error(`api param '${paramKey}' is missing for '${path}'`);
    });
  }

  private generateContext(options?: FunctionCallOptions | undefined): HttpContext {
    const context = new HttpContext();
    context.set(BYPASS_ERROR_HANDLER, !!options?.bypassErrorHandler);

    return context;
  }

  private hasKeys(params: any): params is { [key: string]: string } {
    return Object.keys(params || {}).length > 0;
  }

  private call$<RequestType, ResponseType, L extends FunctionName>(
    headers: HttpHeaders,
    method: FunctionMethod<L>,
    url: string,
    context: HttpContext,
    request?: RequestType,
  ): Observable<ResponseType> {
    let response$: Observable<SerializedTimestamp<ResponseType, SerializedTimestampJSON>>;
    switch (method) {
      case 'GET':
        const params = new HttpParams({ fromObject: request || {} });
        response$ = this.httpClient.get<SerializedTimestamp<ResponseType, SerializedTimestampJSON>>(url, {
          context, headers, params,
        });
        break;
      case 'POST':
        response$ = this.httpClient.post<SerializedTimestamp<ResponseType, SerializedTimestampJSON>>(url, request, {
          context, headers,
        });
        break;
      // Obliged to put these cases in commentary by the time these verbs are used in the original type.
      // On the other hand, it's really not right to use a file as both reference type and data storage.
      // The 2 notions should have been separated.
      // TODO: separate data typing from the import file in api-mapping.ts .
      /*case 'DELETE':
        return this.httpClient.delete<ResponseType>(url, { context, headers });*/
      case 'PUT':
        response$ = this.httpClient.put<SerializedTimestamp<ResponseType, SerializedTimestampJSON>>(url, request, {
          context, headers,
        });
        break;
      default:
        return throwError(() => new Error(`unhandled descriptor verb: ${method}`));
    }
    return response$.pipe(map(response => deserializeTimestampFields<ResponseType>(response)));
  }

}
