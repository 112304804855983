export type IsTypeGuard<T> = (input: unknown) => input is T
export type TransformerFunction<I, O> = (input: I) => O

export function isObject(obj: any): obj is Record<string, any> {
  return obj !== null && typeof obj === 'object';
}

export function transformRecursively<Input, Output, ToTransform, TransformFormat>(input: Input, shouldTransform: IsTypeGuard<ToTransform>, transformValue: TransformerFunction<ToTransform, TransformFormat>): Output {
  function transform(value: unknown): unknown {
    if (shouldTransform(value)) {
      return transformValue(value);
    } else if (value instanceof Array) {
      return value.map(transform);
    } else if (isObject(value)) {
      return Object.fromEntries(Object.entries(value).map(([key, v]) => [key, transform(v)]));
    } else {
      return value;
    }
  }

  return transform(input) as Output;
}